import React from "react"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/800.css"
import "@fontsource/bungee"
import "@fontsource/bungee-shade"
import styled from "@emotion/styled"
import { css } from "@emotion/react"


const Container = styled.div`
  margin: 3rem auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";

  .h1_test {
    h1 {
      background-color: yellowgreen;
      border-radius: 5px;
      display: inline-block;
      margin: 1rem;
      padding: 1rem 2rem;
    }
  }

  h1 {
    /* font-family: "Bungee"; */
    /* font-family: "Bungee Shade"; */
    /* font-weight: 800; */
    font-size: 4rem;

    &.one { font-family: "Open Sans"; font-weight: 400; }
    &.two { font-family: "Open Sans"; font-weight: 800; }
    &.three { font-family: "Bungee"; 
      &.bigger { color: #dcdcdc; font-size: 6rem; }
    }
    &.four { font-family: "Bungee Shade"; }
  }

  p {
    font-weight: 400;
  }

  /* .period {
    margin-left: 3px;
    color: yellowgreen;
  } */

`

const underline = css`
  /* border-bottom: 4px solid #f5f5f5; */
  margin: 0;
`

export default function Home() {
  return (
    <Container>
      <h1 className="three bigger" css={underline}>
        JP
        {/* <span className="period">.</span> */}
      </h1>
      <div className="h1_test">
        <h1 className="one">JP</h1>
        <h1 className="two">JP</h1>
        <h1 className="three">JP</h1>
        <h1 className="four">JP</h1>
      </div>
      <p>This is some content.</p>
    </Container>
  )
}
